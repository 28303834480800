.charts {
  flex: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;
  position: sticky;
  top: 0;
  right: 0;
  height: 100vh;
  border: 2px solid #ddd;
  margin: 20px;
  border-radius: 5px;
}

.charts span {
  font-size: 130px;
  color: rgb(85, 180, 109);
}

.charts .item {
  cursor: pointer;
}